<template>
    <div class="config">
        <div>修改密码</div>
        <div>
            <div>
                <el-form  ref="form" label-width="90px" style="max-height: unset;" :rules="rules" :model="form">
                    <el-form-item label="原密码" prop="oldpassword">
                        <el-input v-model="form.oldpassword" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newpassword">
                        <el-input v-model="form.newpassword" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="确认新密码" prop="confirmNewpassword">
                        <el-input v-model="form.confirmNewpassword" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>
                <el-button type="primary" round @click="buttonSubmit('form')">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";
import {Message} from "element-ui";

export default {
    name: "password",
    data() {
        return {
            form:{
                confirmNewpassword:'',
                newpassword:'',
                oldpassword:'',
            },
            rules: {
                confirmNewpassword: [
                    {required: true, message: '请填写新密码', trigger: 'blur'}
                ],
                newpassword: [
                    {required: true, message: '请填写新密码', trigger: 'blur'}
                ],
                oldpassword: [
                    {required: true, message: '请填写原密码', trigger: 'blur'}
                ],
            },
        }
    },
    mounted() {
    },
    methods: {
        /**
         * 确定按钮
         * */
        buttonSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.form.newpassword !== this.form.confirmNewpassword) {
                        Message.error('两次新密码不一致');
                        return;
                    }
                    this.$axios.post('api/kuerp/order/user/resetpwd', {
                        oldpassword:this.form.oldpassword,
                        newpassword:this.form.newpassword,
                    }).then(res => {
                        console.log('res',res)
                        if (res.data.code == 200) {
                            this.form.oldpassword = ''
                            this.form.newpassword = ''
                            this.form.confirmNewpassword = ''
                            Message.success(res.data.msg+'，请重新登录');
                            this.$store.commit('setUserInfo', {data: '', status: false});
                            this.$router.push('/Login');
                        }
                    })
                } else {
                    return false;
                }
            });

        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep {
    .el-form-item__error {
        color: #CF7AE2;
    }
    .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus {
        border-color:#DCDFE6;
    }
    .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before {
        content: '';
    }
    .el-input__inner {
        width: 3.44rem;
        font-size: 0.14rem;
        font-weight: 400;
    }
    .el-button.is-round {
        width: 3.44rem;
        height: 0.55rem;
        background: #4B83F0;
        border-radius: 0.04rem!important;
        margin-left: 0.6rem!important;
        margin-top: 0.3rem!important;
    }
    .el-form-item__label {
        font-size: 0.14rem;
        font-weight: 500;
        color: #2F2F2F;
    }
    .el-form-item {
        margin-bottom: 0.2rem!important;
    }
}
.config {
    height: 6.6rem;
    padding: 0.24rem 0.34rem;
    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }
    >div:nth-child(2) {
        margin-top: 0.5rem;
        display: flex;
        justify-content: center;
        div {

        }
    }
}
</style>
